jQuery(document).ready(function($) { 
	
	$(document).foundation();

	var orklaReportsFadeOut = function() { 
		$('#archiveOverlayWrapper').addClass('fade-transition');
		$('#archiveOverlayWrapper').removeClass('active');
		$('body').removeClass('stop-scrolling');
		$('body').unbind('touchmove');
		$('.button, a').attr('tabindex', -1);
		$('#wrapper .button, #footer .button, #footer a').attr('tabindex', 0);
		$('#topDownloadsArchive').focus();
		window.setTimeout(function() { 
			$('#archiveOverlayWrapper.fade-transition').removeClass('fade-transition');
		}, 500);
	}

	$(document)
	.on('click touchstart', '#topDownloadsArchive', function(e) { 
		e.preventDefault();
		if (!$(this).hasClass('open')) {
			$('#archiveOverlayWrapper').addClass('active');
			$('body').addClass('stop-scrolling');
			$('body').bind('touchmove', function(e){e.preventDefault()});
			$('.button, a').attr('tabindex', 0);
			$('#wrapper .button, #footer .button, #footer a').attr('tabindex', -1);
			$(this).addClass('open');
			$('.archiveOverlay').addClass('open');
		} else {
			// orklaReportsFadeOut();
			// $(this).removeClass('open');
		}
	})
	.on('click touchstart', '.archiveOverlay .close', function(e) {
		e.preventDefault();
		$(this).closest('.archiveOverlay.open').removeClass('open');
		orklaReportsFadeOut();
	});

	if ($('#highlightsGraphs').length>0) {
		/*
		var waypoint = new Waypoint({
			element: document.getElementById('highlightsGraphs'),
			handler: function() {
				activateCharts();
			}, 
			offset: 250
		});
		*/
		var inview = new Waypoint.Inview({
			element: document.getElementById('highlightsGraphs'),
			enter: function(direction) {
				activateCharts();
			},
		});
	}

	var chartsInactive = true;

	var activateCharts = function() { 
		if (chartsInactive) { 

			var graphLanguage = $('#highlightsGraphs').attr('data-graph-language');
			chartsInactive = false;
			Chart.defaults.global.defaultFontColor = '#222222';
			Chart.defaults.global.defaultFontStyle = 'bold';
			Chart.defaults.global.defaultFontFamily = 'museo-sans, Helvetica Neue, Helvetica, Roboto, Arial, sans-serif';

			var chartOptions = {
				legend: {
					display: false,
					position: 'top',
				},
				tooltips: {
					enabled: false
				},
				hover: {
					animationDuration: 0
				},
				animation: {
					duration: 500,
					onComplete: function () {
						var chartInstance = this.chart,
							ctx = chartInstance.ctx;
						ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
						ctx.textAlign = 'center';
						ctx.textBaseline = 'middle';


			        
				        var lineHeight = 0; // <----

				        // draw line
				        this.chart.ctx.beginPath();
				        var lineOffset = this.chart.height*.549;
				        this.chart.ctx.moveTo(0, lineOffset);
				        this.chart.ctx.strokeStyle = '#cccccc';
				        this.chart.ctx.lineTo(this.chart.width, lineOffset);
				        this.chart.ctx.stroke();

						this.data.datasets.forEach(function (dataset, i) {
							var meta = chartInstance.controller.getDatasetMeta(i);
							meta.data.forEach(function (bar, index) {
								var data = dataset.data[index];
								var newY = (parseInt(data)<=-1) ? bar._model.y + 25 : bar._model.y - 10;                
								ctx.fillStyle = '#222222';
								var dataLabel = data;
								if (data=='3') {
									data += '.0';
								}
								dataLabel = data + ((dataset.label=='%') ? '%' : '');
								if (graphLanguage==='no') {
									dataLabel = dataLabel.replace('.',',');
								}
								ctx.fillText(dataLabel, bar._model.x, newY);
							});
						});
					}
				},
				scales: {
					xAxes: [{
						gridLines: {
							display:false,
                			offsetGridLines:true,
							drawBorder: false,
						},
						ticks: {
							display: false,
						}
					}],
					yAxes: [{
		    			display: false,
						gridLines: {
							display:false,
							drawBorder: false,
						},
						ticks: {
							max: 5.25,
							min: -4,
							drawBorder: false,
						}
					}]
				}
			};
			var barColors = [
				'rgba(54, 47, 51, 1)',
				'rgba(54, 47, 51, 1)',
				'rgba(54, 47, 51, 1)',
				'rgba(54, 47, 51, 1)',
				'rgba(168, 13, 34, 1)'
			];


			var ctx = document.getElementById("organiskVekstChart");
			var chartLabel = $('#chart1').attr('data-axis-title');
			var myChart = new Chart(ctx, {
				type: 'bar',
				data: {
					labels: ["12", "13", "14", "15", "16"],
					datasets: [{
						label: chartLabel,
						data: [1.1, -2.8, 0.7, 2.8, 1.8],
						fontColor: '#222222',
						backgroundColor: barColors,
						borderColor: barColors,
						borderWidth: 0
					}]
				},
				options: chartOptions
			});

			var ctx = document.getElementById("ebitAdjChart");
			var chartLabel = $('#chart2').attr('data-axis-title');
			var myChart = new Chart(ctx, {
				type: 'bar',
				data: {
					labels: ["12", "13", "14", "15", "16"],
					datasets: [{
						label: chartLabel,
						data: [2.8, 3.0, 3.4, 3.8, 4.3],
						fontColor: '#222222',
						backgroundColor: barColors,
						borderColor: barColors,
						borderWidth: 0
					}]
				},
				options: chartOptions
			});

			var ctx = document.getElementById("resultatChart");
			var chartLabel = $('#chart3').attr('data-axis-title');
			var myChart = new Chart(ctx, {
				type: 'bar',
				data: {
					labels: ["12", "13", "14", "15", "16"],
					datasets: [{
						label: chartLabel,
						data: [1.56, 0.68, 1.63, 3.24, 4.22],
						fontColor: '#222222',
						backgroundColor: barColors,
						borderColor: barColors,
						borderWidth: 0
					}]
				},
				options: chartOptions
			});

			$('.chart-wrapper.inactive').removeClass('inactive');
		}
	}

	if ($('#expanding-blocks').length>0) {
		var hideLabel = $('#expanding-blocks').attr('data-hide');
		var readMoreLabel = $('#expanding-blocks').attr('data-read-more');
		$('#expanding-blocks .block-column').each(function() { 
			if ($(this).innerHeight()>300) {
				$(this).addClass('minimised').find('.flexible-height-wrapper').addClass('minimised');
				$(this).append('<button class="button white chevron show-more" data-hide-label="' + hideLabel + '" data-show-label="' + readMoreLabel + '">' + readMoreLabel + '</a>');
			}
		});
	}


	$(document)
	.on('click', '.show-more', function(e) {
		e.preventDefault();
		var totalHeight = $(this).closest('.block-column').find('ul').innerHeight();
		var heightClass = '.' + $(this).attr('data-match-height-class');
		var target = $(this).closest('.block-column').find('.flexible-height-wrapper');
		if (target.hasClass('minimised')) {
			target.removeClass('minimised');
			$(this).closest('.block-column').removeClass('minimised');
			$(this).html($(this).attr('data-hide-label'));
		} else {
			target.addClass('minimised');
			$(this).closest('.block-column').addClass('minimised');
			$(this).html($(this).attr('data-show-label'));
		}

	});

	jQuery(document).ready(function($) {
		$(document)
		.on('click', '.download-annual-report', function() {
			ga('send', 'event', 'Klikk', 'Klikket last ned', 'Årsrapport');
		})
		.on('click', '.download-sustainability-report', function() {
			ga('send', 'event', 'Klikk', 'Klikket last ned', 'Bærekraftsrapport');
		});
	});


});