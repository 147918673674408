jQuery(document).ready(function($) { 
	$(document).foundation();
	$('#highlightsTable').addClass('inactive hidden');
	$('#highlights a').removeClass('hidden');

	var orklaFirstIndex = 2, toInactiveIndex = 0, toActiveIndex = 0, orklaExtraInactiveClass = 'hide-left';

	var orklaReportsFadeOut = function() { 
		$('#archiveOverlayWrapper').addClass('fade-transition');
		$('#archiveOverlayWrapper').removeClass('active');
		$('body').removeClass('stop-scrolling');
		$('body').unbind('touchmove');
		$('.button, a').attr('tabindex', -1);
		$('#wrapper .button, #footer .button, #footer a').attr('tabindex', 0);
		$('#topDownloadsArchive').focus();
		window.setTimeout(function() { 
			$('#archiveOverlayWrapper.fade-transition').removeClass('fade-transition');
		}, 500);
	}
	$(document)
	.on('click touchstart', '#topDownloadsArchive', function(e) { 
		e.preventDefault();
		if ($('.archiveOverlay.open').length<=0) {
			$('#archiveOverlayWrapper').toggleClass('active');
			$('body').addClass('stop-scrolling');
			$('body').bind('touchmove', function(e){e.preventDefault()});
			$('.button, a').attr('tabindex', 0);
			$('#wrapper .button, #footer .button, #footer a').attr('tabindex', -1);
		} else {
			orklaReportsFadeOut();
		}
		$('.archiveOverlay').toggleClass('open');
	})
	.on('click touchstart', '.archiveOverlay .close', function(e) {
		e.preventDefault();
		$(this).closest('.archiveOverlay.open').removeClass('open');
		orklaReportsFadeOut();
	})
	.on('click touchstart', '#highlights a', function(e) {
		e.preventDefault();
		$(this).toggleClass('open');
		$('#highlightsTable').removeClass('hidden');
		$('#highlightsTable').toggleClass('inactive');
		var label = ($(this).hasClass('open')) ? $(this).attr('data-hide-text') : $(this).attr('data-show-text');
		$(this).html(label);
	})
	.on('click touchstart', '.changeYear', function(e) {
		e.preventDefault();
		var direction = ($(this).hasClass('next')) ? 'next' : 'prev';
		if ((direction=='prev'&&orklaFirstIndex!==2) || (direction=='next'&&orklaFirstIndex!==5)) {
			toInactiveIndex = (direction == 'next') ? orklaFirstIndex : (orklaFirstIndex + 1);
			toActiveIndex = (direction == 'next') ? (orklaFirstIndex + 2) : (orklaFirstIndex - 1);
			orklaFirstIndex = (direction == 'next') ? (orklaFirstIndex + 1) : (orklaFirstIndex - 1);
			$('tr').each(function() { 
				$(this).find('.highlighted').removeClass('highlighted');
				$(this).find('th:nth-child(' + orklaFirstIndex + ')').addClass('highlighted');
				$(this).find('td:nth-child(' + orklaFirstIndex + ')').addClass('highlighted');
				$(this).find('th:nth-child(' + toInactiveIndex + ')').removeClass('active').addClass('inactive');
				$(this).find('td:nth-child(' + toInactiveIndex + ')').removeClass('active').addClass('inactive');
				$(this).find('th:nth-child(' + toActiveIndex + ')').removeClass('inactive').addClass('active');
				$(this).find('td:nth-child(' + toActiveIndex + ')').removeClass('inactive').addClass('active');
				if (orklaFirstIndex==2) {
					$('.changeYear.prev').attr('disabled', true);
				} else if (orklaFirstIndex==5) {
					$('.changeYear.next').attr('disabled', true);
				} else {
					$('.changeYear').attr('disabled', false);
				}
			});
		}
	});
});